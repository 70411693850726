import { Route, Switch } from 'wouter';
import { BrandsCreate, BrandsEdit, BrandsList } from './ui/brands';
import { Footer, Header, Layout, Main, SideNav } from './ui/admin-layout';
import { ApiClientProvider } from './data';
import { FeaturesList, FeaturesCreate, FeaturesEdit } from './ui/features';
import { VStack } from '@chakra-ui/react';
import { MenuItem } from './MenuItem';
import { WatchesList } from './ui/watches/WatchesList';
import { WatchesCreate } from './ui/watches/WatchesCreate';
import { WatchesEdit } from './ui/watches/WatchesEdit';

function App() {
  return (
    <>
      <ApiClientProvider baseUrl="https://timeline.staging.watchclub.app/api/v1">
        <Layout>
          <SideNav>
            <VStack alignItems="stretch">
              <MenuItem href="/brands">Brands</MenuItem>
              <MenuItem href="/features">Features</MenuItem>
              <MenuItem href="/watches">Watches</MenuItem>
            </VStack>
          </SideNav>

          <Header></Header>

          <Main>
            <Switch>
              <Route path="/brands" nest>
                <Switch>
                  <Route path="/" component={BrandsList} />
                  <Route path="/create" component={BrandsCreate} />
                  <Route path="/:id" component={BrandsEdit} />
                </Switch>
              </Route>

              <Route path="/features" nest>
                <Switch>
                  <Route path="/" component={FeaturesList} />
                  <Route path="/create" component={FeaturesCreate} />
                  <Route path="/:id" component={FeaturesEdit} />
                </Switch>
              </Route>

              <Route path="/watches" nest>
                <Switch>
                  <Route path="/" component={WatchesList} />
                  <Route path="/create" component={WatchesCreate} />
                  <Route path="/:id" component={WatchesEdit} />
                </Switch>
              </Route>

              <Route>404, Not Found!</Route>
            </Switch>
          </Main>

          <Footer></Footer>
        </Layout>
      </ApiClientProvider>
    </>
  );
}

export default App;
