import { SingleUploadDTO } from '../../api-client';
import { uploadData } from './uploadData';
import { onProgressCallback, UploadedSingleFile } from './types';

export const uploadSingleFile = async (
  file: File,
  upload: SingleUploadDTO,
  onProgress: onProgressCallback
): Promise<UploadedSingleFile> => {
  const uploaded = await uploadData({
    file,
    signedUrl: upload.signedUrl,
    part: 0,
    chunkSize: file.size,
    onProgress,
  });

  return {
    file,
    upload,
    uploaded: uploaded,
  };
};
