import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Skeleton,
} from '@chakra-ui/react';
import { FieldError } from 'react-hook-form';
import { PropsWithChildren } from 'react';
import { withSlots } from '../../tools/withSlots';

type FieldProps = PropsWithChildren<{
  label?: string;
  helpText?: string;
  error?: FieldError;
  isLoading?: boolean;
}>;

export type FieldSlot = 'label';

export const {
  Component: Field,
  useRegisterSlot: useRegisterFieldSlot,
  useSlotsContext: useFieldSlots,
} = withSlots<FieldSlot, FieldProps>(
  ({ label, helpText, error, isLoading, children }) => {
    const { getSlots } = useFieldSlots();
    const hasError = !!error;
    return (
      <FormControl isInvalid={hasError} id="wtf">
        <FormLabel>
          <HStack alignContent={'center'}>
            <div>{label}</div>
            {getSlots('label')}
          </HStack>
        </FormLabel>
        <Skeleton isLoaded={!isLoading}>{children}</Skeleton>

        {hasError ? (
          <FormErrorMessage>{error.message}</FormErrorMessage>
        ) : (
          helpText && <FormHelperText>{helpText}</FormHelperText>
        )}
      </FormControl>
    );
  }
);

export const FieldLabel = ({ children }: PropsWithChildren) => {
  useRegisterFieldSlot('label', children);
  return null;
};
