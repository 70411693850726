import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import DatePickerCore, { DatePickerProps } from 'react-date-picker';
import { styled as s } from 'styled-components';
import { Flex, useFormControlProps } from '@chakra-ui/react';

const DatePickerContainer = s(Flex)`
  .react-date-picker {
    flex: 1;
  }

  .react-date-picker__wrapper {
    border: none;
  }
`;

export const DatePicker = (props: DatePickerProps) => {
  const { isInvalid } = useFormControlProps({});

  return (
    <DatePickerContainer
      borderRadius={'8px'}
      borderStyle="solid"
      borderWidth="1px"
      height="40px"
      borderColor={isInvalid ? 'red' : 'E2E8F0'}
      paddingX="1rem"
      justifyContent="stretch"
    >
      <DatePickerCore {...props} />
    </DatePickerContainer>
  );
};