import {
  Box,
  Button,
  HStack,
  Input,
  Select,
  Textarea,
  VStack
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import moment from 'moment';
import { PropsWithChildren, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { CreateFeatureDTO } from '../../api-client/models/CreateFeatureDTO';
import { withSlots } from '../../tools/withSlots';
import { DatePicker } from '../forms/DatePicker';
import { Field } from '../forms/Field';

type Nullable<T> = { [K in keyof T]: T[K] | null };

const FeatureFormDataSchema = z.object({
  // brandId: z.string().optional(),
  type: z.nativeEnum(CreateFeatureDTO.type),
  title: z.string(),
  description: z.string().optional(),
  externalLink: z.string().optional(),
  startDate: z.string().nullable().optional(),
  endDate: z.string().nullable().optional(),
});

export type FeatureFormData = z.infer<typeof FeatureFormDataSchema>;

export type FeaturesFormProps = PropsWithChildren<{
  onSubmit: (data: FeatureFormData) => void;
  isLoading?: boolean;
  isSaving?: boolean;
  data?: Nullable<FeatureFormData>;
  onClose?: () => void;
}>;

export type FeaturesFormSlot = 'header';

export const { Component: FeaturesForm, useRegisterSlot: useRegisterFeatureFormSlot, useSlotsContext: useFeatureFormSlots } = withSlots<
  FeaturesFormSlot,
  FeaturesFormProps
>(
  ({
    onSubmit,
    isSaving,
    isLoading,
    data,
    children,
    onClose,
  }) => {

    const { getSlots } = useFeatureFormSlots();

    const {
      register,
      formState: { errors },
      handleSubmit,
      reset,
      control,
    } = useForm<Nullable<FeatureFormData>>({
      defaultValues: data,
      resolver: zodResolver(FeatureFormDataSchema),
    });

    useEffect(() => {
      if (data) reset(data);
    }, [reset, data]);
    
    const headerSlot = getSlots('header');

    const _onSubmit = useCallback((data: Nullable<FeatureFormData>) => {
      onSubmit(data as unknown as FeatureFormData);
    }, [onSubmit]);

    return (
      <>
        {children}
        <form onSubmit={handleSubmit(_onSubmit)}>
          <VStack spacing={8} width="400px" alignItems={'stretch'}>
            <HStack justifyContent="space-between">
              <Box>{headerSlot}</Box>
              {onClose && <Button onClick={onClose}>Close</Button>}
            </HStack>

            <Field label="Type" error={errors.type} isLoading={isLoading}>
              <Select {...register('type')}>
                <option>Select a type ...</option>
                {Object.entries(CreateFeatureDTO.type).map(([label, value]) => (
                  <option key={`type-${value}`} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
            </Field>

            <Field label="Title" error={errors.title} isLoading={isLoading}>
              <Input {...register('title')} />
            </Field>

            <Field
              label="Description"
              error={errors.description}
              isLoading={isLoading}
            >
              <Textarea {...register('description')} />
            </Field>

            <Field
              label="External Link"
              error={errors.externalLink}
              isLoading={isLoading}
            >
              <Input {...register('externalLink')} />
            </Field>

            <Field
              label="Start Date"
              error={errors.startDate}
              isLoading={isLoading}
            >
              <Controller
                name="startDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    value={value ? new Date(value) : undefined}
                    onChange={(value) =>
                      onChange(
                        value instanceof Date
                          ? moment(value).format('YYYY-MM-DD HH:mm:ss')
                          : undefined
                      )
                    }
                  />
                )}
              />
            </Field>

            <Field
              label="End Date"
              error={errors.endDate}
              isLoading={isLoading}
            >
              <Controller
                name="endDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    value={value ? new Date(value) : undefined}
                    onChange={(value) =>
                      onChange(
                        value instanceof Date
                          ? moment(value).format('YYYY-MM-DD HH:mm:ss')
                          : undefined
                      )
                    }
                  />
                )}
              />
            </Field>

            <HStack justifyContent={'right'} alignSelf={'stretch'}>
              <Button
                isDisabled={isLoading || isSaving}
                type="submit"
                colorScheme="green"
              >
                Save
              </Button>
            </HStack>
          </VStack>
        </form>
      </>
    );
  }
);

export const FeatureFormHeader = ({ children }: PropsWithChildren) => {
  useRegisterFeatureFormSlot('header', children);
  return null;
};
