/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type FileDTO = {
    id: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
    filename: string;
    contentType: string;
    size: number;
    type: FileDTO.type | null;
    createRef: string | null;
};
export namespace FileDTO {
    export enum type {
        WATCH_IMAGE = 'watchImage',
    }
}

