import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Image,
  List,
  ListItem,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

// @todo: make fileIds a generic to support complex file objects e.g FileDTO
export type FileUploadDropzoneValue = { fileIds: string[]; files: File[] };

export type FileUploadDropzoneProps = {
  onChange?: (value: FileUploadDropzoneValue) => void;
  value?: FileUploadDropzoneValue;
};

export const FileUploadDropzone = ({
  onChange,
  value,
}: FileUploadDropzoneProps) => {
  const [fileIds, setFileIds] = useState<string[]>(value?.fileIds || []);
  const [files, setFiles] = useState<File[]>(value?.files || []);

  useEffect(() => {
    setFileIds(value?.fileIds || []);
    setFiles(value?.files || []);
  }, [value]);

  const removeFile = useCallback(
    (fileToRemove: File) => {
      const updatedFiles = files.filter((file) => file !== fileToRemove);
      setFiles(updatedFiles);
      onChange && onChange({ fileIds, files: updatedFiles });
    },
    [fileIds, files, onChange]
  );

  const removeExistingFile = useCallback(
    (fileToRemove: string) => {
      const updatedFileIds = fileIds.filter((file) => file !== fileToRemove);
      onChange && onChange({ fileIds: updatedFileIds, files });
    },
    [fileIds, files, onChange]
  );

  const onDrop = useCallback(
    (newFiles: File[]) => {
      const updatedFiles = [...files, ...newFiles];
      setFiles(updatedFiles);
      onChange && onChange({ fileIds, files: updatedFiles });
    },
    [fileIds, files, onChange]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
    },
  });

  return (
    <VStack>
      {fileIds.length > 0 && (
        <Card>
          <CardHeader>Existing Images</CardHeader>
          <CardBody>
            <List>
              {fileIds.map((file, index) => (
                <ListItem key={`${index}`}>
                  <Image src={`http://localstacl.localhost:4566/file-storage/persisted/${file}`} />
                  <Button onClick={() => removeExistingFile(file)}>
                    Remove
                  </Button>
                </ListItem>
              ))}
            </List>
          </CardBody>
        </Card>
      )}
      <Card {...getRootProps()} textAlign={'center'} color={`rgba(0,0,0,0.6)`}>
        <CardBody>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag 'n' drop some files here, or click to select files</p>
          )}
        </CardBody>
      </Card>
      {files.length > 0 && (
        <Card>
          <CardHeader>New Images</CardHeader>
          <CardBody>
            <List>
              {files.map((file, index) => (
                <ListItem key={`${index}`}>
                  <Image src={URL.createObjectURL(file)} />
                  <Button onClick={() => removeFile(file)}>Remove</Button>
                </ListItem>
              ))}
            </List>
          </CardBody>
        </Card>
      )}
    </VStack>
  );
};
