import { useCallback } from 'react';
import { useLocation } from 'wouter';
import { useApiClient } from '../../data';
import { useToastPromise } from '../../tools/useToastPromise';
import { BrandFormData, BrandFormHeader, BrandsForm } from './BrandsForm';
import { Heading } from '@chakra-ui/react';

const isError = (error: unknown): error is Error => {
  return error instanceof Error;
};

export const BrandsCreate = () => {
  const { refetch: save, loading: isSaving } = useApiClient(
    'brands.createBrand',
    {
      lazy: true,
    }
  );

  const toast = useToastPromise();
  const [, navigate] = useLocation();

  const updateBrand = useCallback(
    async (data: BrandFormData) => {
      const { id } = await toast(save(data), {
        loading: { title: 'Saving brand ...' },
        success: { title: `your changes for ${data.brand} has been saved` },
        error: (error) => {
          if (isError(error)) {
            return {
              title: 'Error saving brand',
              description: `brand was not saved due to an ${error.message}`,
            };
          }

          return { title: 'Error saving brand' };
        },
      });
      navigate(`/${id}`);
    },
    [save, toast, navigate]
  );

  return (
    <BrandsForm
      onSubmit={updateBrand}
      isSaving={isSaving}
      onClose={() => navigate('/')}
    >
      <BrandFormHeader>
        <Heading>Create a brand</Heading>
      </BrandFormHeader>
    </BrandsForm>
  );
};
