import { Box, Button, HStack, Input, VStack, useToast } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import moment from 'moment';
import { PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { withSlots } from '../../tools/withSlots';
import { DatePicker } from '../forms/DatePicker';
import { Field, FieldLabel } from '../forms/Field';
import { CheckboxField } from '../forms/CheckboxField';
import { FileUploadDropzone } from '../forms/FileUploadDropzone';
import { useFileUploader } from '../../tools/useFileUploader';
import { UploadProgressModal } from './UploadProgressModal';

const WatchFormDataSchema = z
  .object({
    // brandId: z.string().optional(),
    modelNo: z.string(),
    nickName: z.string(),
    startDate: z.string(),
    endDate: z.string(),
    startDateApprox: z.coerce.boolean(),
    endDateApprox: z.coerce.boolean(),
    images: z.string().array(),
  })
  .partial();
export type WatchFormData = z.infer<typeof WatchFormDataSchema>;

const WatchFormDataSchemaInternalSchema = WatchFormDataSchema.omit({
  images: true,
}).extend({
  images: z
    .object({
      fileIds: z.string().array(),
      files: z.instanceof(File).array(),
    })
    .optional(),
});

export type WatchFormDataSchemaInternal = z.infer<
  typeof WatchFormDataSchemaInternalSchema
>;

export type WatchesFormProps = PropsWithChildren<{
  onSubmit: (data: WatchFormData) => void;
  isLoading?: boolean;
  isSaving?: boolean;
  data?: WatchFormData;
  onClose?: () => void;
}>;

export type WatchesFormSlot = 'header';

const Header = () => {
  const { getSlots } = useWatchFormSlots();
  return <Box>{getSlots('header')}</Box>;
};

export const {
  Component: WatchesForm,
  useRegisterSlot: useRegisterWatchFormSlot,
  useSlotsContext: useWatchFormSlots,
} = withSlots<WatchesFormSlot, WatchesFormProps>(
  ({ onSubmit, isSaving, isLoading, data, children, onClose }) => {
    const toast = useToast();

    const { uploadFile, isUploading, uploadFileStates } = useFileUploader();

    const internalData = useMemo(():
      | WatchFormDataSchemaInternal
      | undefined => {
      if (!data) return undefined;

      return {
        ...data,
        startDate: data.startDate || '',
        endDate: data.endDate || '',
        images: { fileIds: data.images || [], files: [] },
      };
    }, [data]);
    const {
      register,
      formState: { errors },
      handleSubmit,
      reset,
      control,
      setValue
    } = useForm<WatchFormDataSchemaInternal>({
      defaultValues: internalData,
      resolver: zodResolver(WatchFormDataSchemaInternalSchema),
    });

    useEffect(() => {
      if (internalData) reset(internalData);
    }, [reset, internalData]);

    const _onSubmit = useCallback(
      async (data: WatchFormDataSchemaInternal) => {
        const { images } = data;

        const { files = [], fileIds = [] } = images || {};

        let newFileIds:string[] = [];

        if (files.length) {
          toast({
            title: 'uploading images ...',
          });

          newFileIds = await Promise.all(
            files.map<Promise<string>>(async (file) => {
              const upload = await uploadFile(file);
              return upload.id;
            })
          );

          toast({
            title: 'images uploaded.',
          });
        }

        const updatedFieldIds = [...fileIds, ...newFileIds];
        setValue('images', { fileIds: updatedFieldIds, files: []});

        onSubmit({
          ...data,
          images: updatedFieldIds,
        });
      },
      [onSubmit, setValue, toast, uploadFile]
    );

    return (
      <>
        {children}

        <UploadProgressModal visible={isUploading} files={uploadFileStates}>
          Uploading Images
        </UploadProgressModal>

        <form onSubmit={handleSubmit(_onSubmit)}>
          <VStack spacing={8} width="400px" alignItems={'stretch'}>
            <HStack justifyContent="space-between">
              <Header />
              {onClose && <Button onClick={onClose}>Close</Button>}
            </HStack>

            <Field
              label="Model No."
              error={errors.modelNo}
              isLoading={isLoading}
            >
              <Input {...register('modelNo')} />
            </Field>

            <Field
              label="Nickname"
              error={errors.nickName}
              isLoading={isLoading}
            >
              <Input {...register('nickName')} />
            </Field>

            <Field label="Images" isLoading={isLoading}>
              {/* <Gallery images={images.data} /> */}
              <Controller
                control={control}
                name="images"
                render={({ field: { onChange, value } }) => {
                  return (
                    <FileUploadDropzone
                      value={value}
                      onChange={
                        onChange
                      } /* uploadProgress={fileUploadProgress} */
                    />
                  );
                }}
              />
            </Field>

            <Field
              label="Start Date"
              error={errors.startDate}
              isLoading={isLoading}
            >
              <FieldLabel>
                <HStack alignItems={'center'}>
                  <div>{'('}approx </div>

                  <CheckboxField name="startDateApprox" control={control} />
                  <div>{')'}</div>
                </HStack>
              </FieldLabel>
              <Controller
                name="startDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    value={value ? new Date(value) : undefined}
                    onChange={(value) => {
                      onChange(
                        value instanceof Date
                          ? moment(value).format('YYYY-MM-DD HH:mm:ss')
                          : ''
                      )
                    }
                    }
                  />
                )}
              />
            </Field>

            <Field
              label="End Date"
              error={errors.endDate}
              isLoading={isLoading}
            >
              <FieldLabel>
                <HStack alignItems={'center'}>
                  <div>{'('}approx </div>

                  <CheckboxField name="endDateApprox" control={control} />
                  <div>{')'}</div>
                </HStack>
              </FieldLabel>
              <Controller
                name="endDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    value={value ? new Date(value) : undefined}
                    onChange={(value) =>
                      onChange(
                        value instanceof Date
                          ? moment(value).format('YYYY-MM-DD HH:mm:ss')
                          : ''
                      )
                    }
                  />
                )}
              />
            </Field>

            <HStack justifyContent={'right'} alignSelf={'stretch'}>
              <Button
                isDisabled={isLoading || isSaving}
                type="submit"
                colorScheme="green"
              >
                Save
              </Button>
            </HStack>
          </VStack>
        </form>
      </>
    );
  }
);

export const WatchFormHeader = ({ children }: PropsWithChildren) => {
  useRegisterWatchFormSlot('header', children);
  return null;
};
