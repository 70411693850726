import {
  ComponentType,
  ReactNode,
  createContext,
  memo,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

const useSlots = <SlotTypes extends string = string>() => {
  const [slots, setSlots] = useState<Record<SlotTypes, ReactNode[]>>(
    () => ({}) as Record<SlotTypes, ReactNode[]>
  );

  const getAll = useCallback(
    (slot: SlotTypes) => {
      return slots[slot] || [];
    },
    [slots]
  );

  const set = useCallback((slot: SlotTypes, node: ReactNode) => {
    setSlots((state) => {
      const arr = [...Array.from(state[slot] || []), node];
      return {
        ...state,
        [slot]: arr.filter((node, index) => arr.indexOf(node) === index),
      };
    });
  }, []);

  const unset = useCallback((slot: SlotTypes, node: ReactNode) => {
    setSlots((state) => {
      const arr = Array.from(state[slot] || []);
      arr.splice(arr.indexOf(node), 1);
      return {
        ...state,
        [slot]: arr,
      };
    });
  }, []);

  return {
    set,
    unset,
    getAll,
  };
};

const noOp = () => {
  throw new Error('Layout root component not found');
};

type SlotContextType<SlotType extends string> = {
  setSlot: (slotName: SlotType, node: ReactNode) => void;
  unsetSlot: (slotName: SlotType, node: ReactNode) => void;
  getSlots: (slotName: SlotType) => ReactNode;
};

type SlotRegisterContextType<SlotType extends string> = {
  setSlot: (slotName: SlotType, node: ReactNode) => void;
  unsetSlot: (slotName: SlotType, node: ReactNode) => void;
};

export const withSlots = <SlotTypes extends string, Props extends {}>(
  Component: ComponentType<Props>,
  traceId?: string
): {
  Component: ComponentType<Props>;
  useRegisterSlot: (slot: SlotTypes, content: React.ReactNode) => void;
  useSlotsContext: () => SlotContextType<SlotTypes>;
} => {
  const SlotContext = createContext<SlotContextType<SlotTypes>>({
    setSlot: noOp,
    unsetSlot: noOp,
    getSlots: noOp,
  });

  const SlotRegisterContext = createContext<SlotRegisterContextType<SlotTypes>>(
    {
      setSlot: noOp,
      unsetSlot: noOp,
    }
  );

  const useSlotsContext = () => useContext(SlotContext);

  const useRegisterSlot = (slot: SlotTypes, content: ReactNode) => {
    const { setSlot, unsetSlot } = useContext(SlotRegisterContext);

    useLayoutEffect(() => {
      setSlot(slot, content);
      if (traceId) console.log(`set: ${traceId} ${slot}`);
      return () => {
        unsetSlot(slot, content);
        if (traceId) console.log(`unset: ${traceId} ${slot}`);
      };
    }, [slot, setSlot, unsetSlot, content]);
  };

  const MComponent = memo(Component) as unknown as ComponentType<Props>;
  return {
    Component: (props) => {
      const { unset, set, getAll } = useSlots<SlotTypes>();

      const context = useMemo(() => {
        if (traceId) console.log(`full context: ${traceId}`);
        return {
          setSlot: set,
          unsetSlot: unset,
          getSlots: getAll,
        };
      }, [set, unset, getAll]);

      const registerContext = useMemo(() => {
        if (traceId) console.log(`register context: ${traceId}`);
        return {
          setSlot: set,
          unsetSlot: unset,
        };
      }, [set, unset]);

      return (
        <SlotRegisterContext.Provider value={registerContext}>
          <SlotContext.Provider value={context}>
            <MComponent {...props} />
          </SlotContext.Provider>
        </SlotRegisterContext.Provider>
      );
    },
    useRegisterSlot,
    useSlotsContext,
  };
};
