import { useCallback, useMemo } from 'react';
import { useLocation, useParams } from 'wouter';
import { useApiClient } from '../../data';
import { useToastPromise } from '../../tools/useToastPromise';
import { WatchFormData, WatchFormHeader, WatchesForm } from './WatchesForm';
import { Text, Heading } from '@chakra-ui/react';
import { Skeleton } from '../skeletons';

const isError = (error: unknown): error is Error => {
  return error instanceof Error;
};

export const WatchesEdit = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading } = useApiClient('watches.getWatch', {
    requestBody: [id],
  });

  const watch = useMemo(() => {
    if(!data) return undefined;

    return {
      ...data,
      images: data.images.map(({ id }) => id),
    };
  }, [data]);

  const { refetch: save, loading: isSaving } = useApiClient(
    'watches.updateWatch',
    {
      lazy: true,
    }
  );

  const toast = useToastPromise();

  const updateWatch = useCallback(
    async (data: WatchFormData) => {
      await toast(save(id, data), {
        loading: { title: 'Saving watch ...' },
        success: {
          title: `your changes for "${
            data.nickName || data.modelNo
          }" has been updated`,
        },
        error: (error) => {
          if (isError(error)) {
            return {
              title: 'Error saving watch',
              description: `watch was not saved due to an ${error.message}`,
            };
          }

          return { title: 'Error saving watch' };
        },
      });
    },
    [save, toast, id]
  );

  const [, navigate] = useLocation();

  return (
    <WatchesForm
      onSubmit={updateWatch}
      isLoading={loading}
      isSaving={isSaving}
      data={watch}
      onClose={() => navigate('/')}
    >
      <WatchFormHeader>
        <Heading>Edit watch</Heading>
        <Skeleton isLoaded={!loading}>
          <Text fontSize="md" as="i">
            Last updated: {watch && watch.updatedAt}
          </Text>
        </Skeleton>
      </WatchFormHeader>
    </WatchesForm>
  );
};
