/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FileDTO } from '../models/FileDTO';
import type { MultipartFileUploadedDTO } from '../models/MultipartFileUploadedDTO';
import type { MultipartUploadDTO } from '../models/MultipartUploadDTO';
import type { NewFileSchemaDTO } from '../models/NewFileSchemaDTO';
import type { SingleFileUploadedDTO } from '../models/SingleFileUploadedDTO';
import type { SingleUploadDTO } from '../models/SingleUploadDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FilesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public getSignedUrls(
        requestBody: Array<NewFileSchemaDTO>,
    ): CancelablePromise<Array<(SingleUploadDTO | MultipartUploadDTO)>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/files/signed-urls',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns FileDTO
     * @throws ApiError
     */
    public completeUploads(
        requestBody: Array<(SingleFileUploadedDTO | MultipartFileUploadedDTO)>,
    ): CancelablePromise<Array<FileDTO>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/files/complete-uploads',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
