import {
  HStack,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text
} from '@chakra-ui/react';
import { PropsWithChildren, useMemo } from 'react';
import { FileUploadState } from '../../tools/useFileUploader/types';

export type UploadProgressModalProps = PropsWithChildren<{
  visible: boolean;
  files: FileUploadState[];
}>;

const noOp = () => undefined;

export const UploadProgressModal = ({
  visible,
  children,
  files,
}: UploadProgressModalProps) => {
  const completed = useMemo(() => {
    return files.filter(({ status }) => status === 'complete').length;
  }, [files]);
  return (
    <>
      <Modal
        isOpen={visible}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        onClose={noOp}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={'center'}>
            <HStack justifyContent={'center'}>
              <Spinner />{' '}
              <Text>
                {children} {`${completed} / ${files.length}`}
              </Text>
            </HStack>
          </ModalHeader>
        </ModalContent>
      </Modal>
    </>
  );
};
