import pLimit from 'p-limit';
import { useCallback, useMemo, useState } from 'react';
import { useApiClient } from '../../data';
import {
  FileUploadState,
  FileUploadStateInternal,
  isSingleUpload,
  isUploadedMultipartFile,
} from './types';
import { uploadMultipartFile } from './uploadMultipartFile';
import { uploadSingleFile } from './uploadSingleFile';

const fileUploadBatch = pLimit(2);

export const useFileUploader = () => {
  const [uploadFileStates, setFileUploadStates] = useState<FileUploadState[]>(
    []
  );

  const updateFileUpload = ({
    file,
    status,
    uploadedBytes,
    error,
  }: FileUploadStateInternal) => {
    setFileUploadStates((fileUploadStates) => {
      const state = fileUploadStates.find((state) => state.file === file) || {
        file,
        status,
        uploadedBytes: 0,
      };
      return [
        ...fileUploadStates.filter((state) => state.file !== file),
        {
          ...state,
          status,
          uploadedBytes: uploadedBytes || state.uploadedBytes,
          error,
        },
      ];
    });
  };

  const clearFiles = () => setFileUploadStates([]);

  const { refetch: getSignedUrls } = useApiClient('files.getSignedUrls', {
    lazy: true,
  });

  const { refetch: completeFileUploads } = useApiClient(
    'files.completeUploads',
    {
      lazy: true,
    }
  );

  const initFileUploads = useCallback(
    async (files: File[]) => {
      const uploads = await getSignedUrls(
        files.map((file, index) => ({
          filename: file.name,
          ref: `${index}-${file.name}`,
          size: file.size,
          contentType: file.type,
        }))
      );

      const uploadedFiles = await Promise.all(
        uploads.map(async (upload) => {
          const file = files.find(
            (file, index) => upload.ref === `${index}-${file.name}`
          );

          if (!file) {
            throw new Error(
              `Unexpectedly, could not find file with reference "${upload.ref}"!`
            );
          }

          try {
            const resposne = await fileUploadBatch(async () => {
              updateFileUpload({ file, status: 'queued' });
              if (isSingleUpload(upload)) {
                return await uploadSingleFile(file, upload, updateFileUpload);
              } else {
                return await uploadMultipartFile(
                  file,
                  upload,
                  updateFileUpload
                );
              }
            });
            updateFileUpload({ file, status: 'complete' });
            return resposne;
          } catch (error) {
            updateFileUpload({ file, status: 'failed', error });
          }
        })
      );

      return await completeFileUploads(
        uploadedFiles
          .filter(<T>(upload: T | undefined): upload is T => {
            return !!upload;
          })
          .map((upload) => {
            if (isUploadedMultipartFile(upload)) {
              return {
                id: upload.upload.id,
                uploadId: upload.upload.uploadId,
                parts: upload.uploaded,
              };
            }

            return {
              id: upload.upload.id!,
            };
          })
      );
    },
    [getSignedUrls, completeFileUploads]
  );
    
  const isUploading = useMemo(() => {
    return !!uploadFileStates.find(({ status }) => status === 'uploading');
  }, [uploadFileStates]);

  const uploadFile = useCallback(
    async (file: File) => {
      const uploads = await initFileUploads([file]);
      console.log('!!!', uploads)
      return uploads[0];
    },
    [initFileUploads]
  );

  return {
    uploadFiles: initFileUploads,
    uploadFile,
    isUploading,
    uploadFileStates,
    clearFiles,
  };
};
