/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';
import { BrandsService } from './services/BrandsService';
import { FeaturesService } from './services/FeaturesService';
import { FilesService } from './services/FilesService';
import { WatchesService } from './services/WatchesService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class WCTimelineClient {
    public readonly brands: BrandsService;
    public readonly features: FeaturesService;
    public readonly files: FilesService;
    public readonly watches: WatchesService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1.0.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.brands = new BrandsService(this.request);
        this.features = new FeaturesService(this.request);
        this.files = new FilesService(this.request);
        this.watches = new WatchesService(this.request);
    }
}

