/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type FeatureDTO = {
    id: string;
    brandId: string | null;
    createdAt: string;
    updatedAt: string;
    type: FeatureDTO.type | null;
    title: string;
    description: string | null;
    externalLink: string | null;
    startDate: string | null;
    endDate: string | null;
};
export namespace FeatureDTO {
    export enum type {
        CLASP = 'clasp',
        BEVEL = 'bevel',
        LOGO = 'logo',
        STRAP = 'strap',
    }
}

