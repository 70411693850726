import { ChakraProvider, Flex, HStack, VStack } from '@chakra-ui/react';
import {
  PropsWithChildren
} from 'react';
import { styled as s } from 'styled-components';
import { withSlots } from '../../tools/withSlots';


const SideNav = s(VStack)`
  border-right: solid 1px rgba(0,0,0,0.1);
  width: 200px;
  justify-content: flext-start;
  align-items: stretch;
  min-height: 100vh;
  padding-top: 100px;
  position: fixed;
`;

export type LayoutSlot = 'side-nav' | 'main';

type LayoutProps = PropsWithChildren;

export const {
  Component: Layout,
  useRegisterSlot: useRegisterLayoutSlot,
  useSlotsContext: useLayoutSlots,
} = withSlots<LayoutSlot, LayoutProps>(({ children }) => {
  const { getSlots } = useLayoutSlots();
  return (
    <ChakraProvider>
      <HStack alignItems="flex-start" spacing={12}>
        <SideNav alignItems="stretch">{getSlots('side-nav')}</SideNav>
        <Flex mt="100px" ml="230px" flexGrow={1}>
          {getSlots('main')}
        </Flex>
      </HStack>
      {children}
    </ChakraProvider>
  );
});
