/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CreateFeatureDTO = {
    brandId?: string;
    type?: CreateFeatureDTO.type;
    title?: string;
    description?: string;
    externalLink?: string;
    startDate?: string | null;
    endDate?: string | null;
};
export namespace CreateFeatureDTO {
    export enum type {
        CLASP = 'clasp',
        BEVEL = 'bevel',
        LOGO = 'logo',
        STRAP = 'strap',
    }
}

