import pLimit from 'p-limit';
import { MultipartUploadDTO } from '../../api-client';
import {
  FileUploadState,
  onProgressCallback,
  UploadedMultipartFile
} from './types';
import { uploadData } from './uploadData';
const multipartFileUploadBatch = pLimit(3);

export type onMulitpartProgress = (
  part: number,
  progress: FileUploadState
) => void;

export const uploadMultipartFile = async (
  file: File,
  upload: MultipartUploadDTO,
  onProgress: onProgressCallback
): Promise<UploadedMultipartFile> => {
  const { parts } = upload;

  const bytes = Array.from<number>({ length: parts.length }).fill(0);

  const onMulitpartProgress: onMulitpartProgress = (
    part,
    { file, status, uploadedBytes }
  ) => {
    bytes[part] = uploadedBytes;
    const totalBytesUploaded = bytes.reduce(
      (sum, bytePart) => sum + bytePart,
      0
    );

    onProgress({
      file,
      status,
      uploadedBytes: totalBytesUploaded,
    });
  };

  const uploaded = await Promise.all(
    parts.map(({ signedUrl, part, chunkSize }) => {
      return multipartFileUploadBatch(async () => {
        const { etag } = await uploadData({
          file,
          signedUrl,
          part,
          chunkSize,
          onProgress: (...args) => onMulitpartProgress(part, ...args),
        });

        return {
          part,
          etag,
        };
      });
    })
  );

  return {
    file,
    upload,
    uploaded,
  };
};
