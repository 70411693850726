import { useCallback } from 'react';
import { useLocation, useParams } from 'wouter';
import { useApiClient } from '../../data';
import { useToastPromise } from '../../tools/useToastPromise';
import { BrandFormData, BrandFormHeader, BrandsForm } from './BrandsForm';
import { Text, Heading } from '@chakra-ui/react';
import { Skeleton } from '../skeletons';

const isError = (error: unknown): error is Error => {
  return error instanceof Error;
};

export const BrandsEdit = () => {
  const { id } = useParams<{ id: string }>();

  const { data: brand, loading } = useApiClient('brands.getBrand', {
    requestBody: [id],
  });

  const { refetch: save, loading: isSaving } = useApiClient(
    'brands.updateBrand',
    {
      lazy: true,
    }
  );

  const toast = useToastPromise();

  const updateBrand = useCallback(
    async (data: BrandFormData) => {
      await toast(save(id, data), {
        loading: { title: 'Saving brand ...' },
        success: { title: `your changes for ${data.brand} has been updated` },
        error: (error) => {
          if (isError(error)) {
            return {
              title: 'Error saving brand',
              description: `brand was not saved due to an ${error.message}`,
            };
          }

          return { title: 'Error saving brand' };
        },
      });
    },
    [save, toast, id]
  );

  const [, navigate] = useLocation();

  return (
    <BrandsForm
      onSubmit={updateBrand}
      isLoading={loading}
      isSaving={isSaving}
      data={brand}
      onClose={() => navigate('/')}
    >
      <BrandFormHeader>
        <Heading>Edit brand</Heading>
        <Skeleton isLoaded={!loading}>
          <Text fontSize="md" as="i">
            Last updated: {brand && brand.updatedAt}
          </Text>
        </Skeleton>
      </BrandFormHeader>
    </BrandsForm>
  );
};
