import { useCallback } from 'react';
import { useLocation, useParams } from 'wouter';
import { useApiClient } from '../../data';
import { useToastPromise } from '../../tools/useToastPromise';
import { FeatureFormData, FeatureFormHeader, FeaturesForm } from './FeaturesForm';
import { Text, Heading } from '@chakra-ui/react';
import { Skeleton } from '../skeletons';

const isError = (error: unknown): error is Error => {
  return error instanceof Error;
};

export const FeaturesEdit = () => {
  const { id } = useParams<{ id: string }>();

  const { data: feature, loading } = useApiClient('features.getFeature', {
    requestBody: [id],
  });

  const { refetch: save, loading: isSaving } = useApiClient(
    'features.updateFeature',
    {
      lazy: true,
    }
  );

  const toast = useToastPromise();

  const updateFeature = useCallback(
    async (data: FeatureFormData) => {
      await toast(save(id, data), {
        loading: { title: 'Saving feature ...' },
        success: { title: `your changes for "${data.title}" has been updated` },
        error: (error) => {
          if (isError(error)) {
            return {
              title: 'Error saving feature',
              description: `feature was not saved due to an ${error.message}`,
            };
          }

          return { title: 'Error saving feature' };
        },
      });
    },
    [save, toast, id]
  );

  const [, navigate] = useLocation();

  return (
    <FeaturesForm
      onSubmit={updateFeature}
      isLoading={loading}
      isSaving={isSaving}
      data={feature}
      onClose={() => navigate('/')}
    >
      <FeatureFormHeader>
        <Heading>Edit feature</Heading>
        <Skeleton isLoaded={!loading}>
          <Text fontSize="md" as="i">
            Last updated: {feature && feature.updatedAt}
          </Text>
        </Skeleton>
      </FeatureFormHeader>
    </FeaturesForm>
  );
};
