import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { WCTimelineClient } from '../api-client';
import { ConditionalPickDeep, Paths, Get } from 'type-fest';
import { method } from 'lodash';

type ApiClientContextType = {
  client: WCTimelineClient;
};
const ApiClientContext = createContext<ApiClientContextType>({
  client: {} as unknown as WCTimelineClient,
});

type ApiClientProviderProps = PropsWithChildren<{
  baseUrl: string;
}>;

export const ApiClientProvider = ({
  baseUrl,
  children,
}: ApiClientProviderProps) => {
  const client = useMemo(() => {
    return new WCTimelineClient({ BASE: baseUrl });
  }, [baseUrl]);

  const contextValue = useMemo(
    () => ({
      client,
    }),
    [client]
  );

  return (
    <ApiClientContext.Provider value={contextValue}>
      {children}
    </ApiClientContext.Provider>
  );
};

type Method<ObjectType, MethodPath> = MethodPath extends string
  ? Get<ObjectType, MethodPath>
  : never;

export type useApiClientArgs<
  MethodPath,
  RequestParams,
> = [
  method: MethodPath,
  options?: {
    lazy?: boolean;
    requestBody?: RequestParams;
  },
];
// @todo: describe exact combinations..
// > = [
//   method: MethodPath,
//   options?: {
//     lazy: true;
//   } | {
//     requestBody: RequestParams;
//   },
// ];

export type useApiClientResponse<Response, RequestParams extends unknown[]> = {
  data?: Response;
  error?: Error;
  loading: boolean;
  refetch: (...args: RequestParams) => Promise<Response>;
  reset: () => void;
};

type AnyFunction = (...args: any[]) => any;

type NoInfer<T> = [T][T extends any ? 0 : never];

export const useApiClient = <
  ObjectType extends object = WCTimelineClient,
  MethodPath extends string = Extract<
    Paths<ConditionalPickDeep<ObjectType, Function>>,
    string
  >,
  RequestParams extends unknown[] = Method<
    ObjectType,
    MethodPath
  > extends AnyFunction
    ? Parameters<Method<ObjectType, MethodPath>>
    : never[],
  Response = Method<ObjectType, MethodPath> extends AnyFunction
    ? Awaited<ReturnType<Method<ObjectType, MethodPath>>>
    : never,
>(...args: useApiClientArgs<MethodPath, NoInfer<RequestParams>>): useApiClientResponse<
  Response,
  NoInfer<RequestParams>
> => {
  const [methodName, options] = args;
  const {
    requestBody,
    lazy
  } = options || {}

  const { client } = useContext(ApiClientContext);

  const [data, setData] = useState<Response>();
  const [error, setError] = useState<Error | undefined>();
  const [loading, setloading] = useState(false);
  const [isFetched, setFetched] = useState(false);

  const reset = useCallback(() => {
    setData(undefined);
    setError(undefined);
    setloading(false);
    setFetched(false);
  }, []);

  const fetchData = useCallback(
    async (...args: RequestParams): Promise<Response> => {
      setloading(true);
      try {
        const response = await method(methodName, ...args)(client);
        setData(response);
        setloading(false);
        setError(undefined);
        return response;
      } catch (error) {
        setError(error as Error);
        setloading(false);
        throw error;
      }
    },
    [methodName, client]
  );

  const refetch = useCallback(
    (...args: RequestParams) => {
      return fetchData(...args);
    },
    [fetchData]
  );

  useEffect(() => {
    if (lazy) return;

    if (isFetched) return;

    setFetched(true);

    fetchData(...(requestBody || ([] as unknown as RequestParams))).catch(
      (error: Error) => {
        console.error(`Fetch Data Error: ${error}`);
      }
    );
  }, [requestBody, lazy, isFetched, fetchData]);

  return { data, error, loading, refetch, reset };
};
