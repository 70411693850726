import { styled as s } from 'styled-components';
import { useRoute, Link, LinkProps } from 'wouter';

const Container = s(Link)<{ $isActive: boolean }>`
display: flex;
flex: 1;
padding: 8px 16px;
text-decoration: none;

&:hover {
  background-color: rgba(0,0,0,0.1);
  text-decoration: none;
}

${({ $isActive }) =>
  $isActive
    ? `
  background-color: rgba(0,0,0,0.1);
`
    : undefined}
`;

export const MenuItem = ({ ...props }: LinkProps) => {
  const { to, href } = props;
  const [match] = useRoute(href || to || '');
  const isActive = !!match && (!!href || !!to);

  return <Container {...props} $isActive={isActive} />;
};
