import { Checkbox, CheckboxProps } from '@chakra-ui/react';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form';

type CheckboxFieldProps<TFieldValues extends FieldValues = FieldValues> =
  CheckboxProps & {
    name: ControllerProps<TFieldValues>['name'];
    control: ControllerProps<TFieldValues>['control'];
  };
export const CheckboxField = <TFieldValues extends FieldValues = FieldValues>({
  name,
  control,
  ...props
}: CheckboxFieldProps<TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return <Checkbox {...props} isChecked={!!value} onChange={onChange} />;
      }}
    />
  );
};
