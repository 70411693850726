import { MultipartUploadDTO, SingleUploadDTO } from '../../api-client';

export type FileUploadStatus = 'queued' | 'uploading' | 'complete' | 'failed';

export type UploadProgress = {
  progress: number;
  bytes: number;
};

export type UploadedSingleFile = {
  file: File;
  upload: SingleUploadDTO;
  uploaded: { etag: string };
};

export type UploadedMultipartFile = {
  file: File;
  upload: MultipartUploadDTO;
  uploaded: { part: number; etag: string }[];
};

export type FileUploadStateInternal = {
  file: File;
  status: FileUploadStatus;
  uploadedBytes?: number;
  error?: unknown;
}

export type FileUploadState = {
  file: File;
  status: FileUploadStatus;
  uploadedBytes: number;
  error?: unknown;
}

export type onProgressCallback = (
  state: FileUploadState
) => void;

export type uploadDataParams = {
  file: File;
  signedUrl: string;
  part: number;
  chunkSize: number;
  onProgress: onProgressCallback;
};

export const isSingleUpload = (upload: object): upload is SingleUploadDTO => {
  return !!(upload as { signedUrl: string }).signedUrl;
};

export const isUploadedMultipartFile = (
  upload: object
): upload is UploadedMultipartFile => {
  return !!(upload as { upload: MultipartUploadDTO }).upload;
};
