import { Heading } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useLocation } from 'wouter';
import { useApiClient } from '../../data';
import { useToastPromise } from '../../tools/useToastPromise';
import { WatchFormData, WatchFormHeader, WatchesForm } from './WatchesForm';

const isError = (error: unknown): error is Error => {
  return error instanceof Error;
};

export const WatchesCreate = () => {
  const { refetch: save, loading: isSaving } = useApiClient(
    'watches.createWatch',
    {
      lazy: true,
    }
  );

  const toast = useToastPromise();
  const [, navigate] = useLocation();

  const updateFeature = useCallback(
    async (data: WatchFormData) => {
      const { id } = await toast(save(data), {
        loading: { title: 'Saving brand ...' },
        success: {
          title: `your changes for ${
            data.nickName || data.modelNo
          } has been saved`,
        },
        error: (error) => {
          if (isError(error)) {
            return {
              title: 'Error saving watch',
              description: `watch was not saved due to an ${error.message}`,
            };
          }

          return { title: 'Error saving watch' };
        },
      });
      navigate(`/${id}`);
    },
    [save, toast, navigate]
  );

  return (
    <WatchesForm
      onSubmit={updateFeature}
      isSaving={isSaving}
      onClose={() => navigate('/')}
    >
      <WatchFormHeader>
        <Heading>Create a watch</Heading>
      </WatchFormHeader>
    </WatchesForm>
  );
};
