import { useCallback } from 'react';
import { useLocation } from 'wouter';
import { useApiClient } from '../../data';
import { useToastPromise } from '../../tools/useToastPromise';
import { FeatureFormData, FeatureFormHeader, FeaturesForm } from './FeaturesForm';
import { Heading } from '@chakra-ui/react';

const isError = (error: unknown): error is Error => {
  return error instanceof Error;
};

export const FeaturesCreate = () => {
  const { refetch: save, loading: isSaving } = useApiClient(
    'features.createFeature',
    {
      lazy: true,
    }
  );

  const toast = useToastPromise();
  const [, navigate] = useLocation();

  const updateFeature = useCallback(
    async (data: FeatureFormData) => {
      const { id } = await toast(save(data), {
        loading: { title: 'Saving brand ...' },
        success: { title: `your changes for ${data.title} has been saved` },
        error: (error) => {
          if (isError(error)) {
            return {
              title: 'Error saving brand',
              description: `brand was not saved due to an ${error.message}`,
            };
          }

          return { title: 'Error saving brand' };
        },
      });
      navigate(`/${id}`);
    },
    [save, toast, navigate]
  );

  return (
    <FeaturesForm
      onSubmit={updateFeature}
      isSaving={isSaving}
      onClose={() => navigate('/')}
    >
      <FeatureFormHeader>
        <Heading>Create a feature</Heading>
      </FeatureFormHeader>
    </FeaturesForm>
  );
};
