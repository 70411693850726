import {
  UseToastOptions,
  useToast
} from '@chakra-ui/react';

type UseToastPromiseOption = Omit<UseToastOptions, 'status'>;

const isFixedUseToastPromiseOption = (
  opts: unknown
): opts is FixedUseToastPromiseOption => {
  return typeof opts !== 'function';
};

type FixedUseToastPromiseOption = Omit<UseToastPromiseOption, 'status'>;

export type useToastPromiseOptions<Result> = {
  loading: FixedUseToastPromiseOption;
  success:
    | FixedUseToastPromiseOption
    | ((result: Result) => FixedUseToastPromiseOption);
  error:
    | FixedUseToastPromiseOption
    | ((error: unknown) => FixedUseToastPromiseOption);
};
export const useToastPromise = () => {
  const toast = useToast();

  return async <Result = unknown>(
    promise: Promise<Result>,
    options: useToastPromiseOptions<Result>
  ): Promise<Result> => {
    const { loading, error: errorOpts, success } = options;

    try {
      const handle = toast({
        ...loading,
        status: 'loading',
      });

      const result = await promise;

      toast.close(handle);
      
      const successToastOpts: UseToastOptions = isFixedUseToastPromiseOption(
        success
      )
        ? success
        : success(result);

      toast({
        ...successToastOpts,
        status: 'success',
      });

      return result;
    } catch (error) {
      const errorToastOpts: UseToastOptions = isFixedUseToastPromiseOption(
        errorOpts
      )
        ? errorOpts
        : errorOpts(error);

      toast({
        ...errorToastOpts,
        status: 'error',
      });
      throw error;
    }
  };
};
