/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateFeatureDTO } from '../models/CreateFeatureDTO';
import type { CreateResponseDTO } from '../models/CreateResponseDTO';
import type { FeatureDTO } from '../models/FeatureDTO';
import type { UpdateFeatureDTO } from '../models/UpdateFeatureDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FeaturesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param requestBody
     * @returns CreateResponseDTO
     * @throws ApiError
     */
    public createFeature(
        requestBody: CreateFeatureDTO,
    ): CancelablePromise<CreateResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/features',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param size
     * @param page
     * @returns FeatureDTO
     * @throws ApiError
     */
    public getFeatureList(
        size: number = 30,
        page: number = 1,
    ): CancelablePromise<Array<FeatureDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/features',
            query: {
                'size': size,
                'page': page,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public updateFeature(
        id: string,
        requestBody: UpdateFeatureDTO,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/features/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns FeatureDTO
     * @throws ApiError
     */
    public getFeature(
        id: string,
    ): CancelablePromise<FeatureDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/features/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public deleteFeature(
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/features/{id}',
            path: {
                'id': id,
            },
        });
    }
}
