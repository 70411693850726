/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateResponseDTO } from '../models/CreateResponseDTO';
import type { FullWatchDTO } from '../models/FullWatchDTO';
import type { UpdateWatchDTO } from '../models/UpdateWatchDTO';
import type { WatchDTO } from '../models/WatchDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class WatchesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param id
     * @returns FullWatchDTO
     * @throws ApiError
     */
    public getWatch(
        id: string,
    ): CancelablePromise<FullWatchDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/watches/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public updateWatch(
        id: string,
        requestBody: UpdateWatchDTO,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/watches/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public deleteWatch(
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/watches/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param requestBody
     * @returns CreateResponseDTO
     * @throws ApiError
     */
    public createWatch(
        requestBody: UpdateWatchDTO,
    ): CancelablePromise<CreateResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/watches',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param size
     * @param page
     * @returns WatchDTO
     * @throws ApiError
     */
    public getWatchList(
        size: number = 30,
        page: number = 1,
    ): CancelablePromise<Array<WatchDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/watches',
            query: {
                'size': size,
                'page': page,
            },
        });
    }
}
