/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BrandDTO } from '../models/BrandDTO';
import type { CreateBrandDTO } from '../models/CreateBrandDTO';
import type { CreateResponseDTO } from '../models/CreateResponseDTO';
import type { UpdateBrandDTO } from '../models/UpdateBrandDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class BrandsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param size
     * @param page
     * @returns BrandDTO
     * @throws ApiError
     */
    public getBrandList(
        size: number = 30,
        page: number = 1,
    ): CancelablePromise<Array<BrandDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/brands',
            query: {
                'size': size,
                'page': page,
            },
        });
    }
    /**
     * @param requestBody
     * @returns CreateResponseDTO
     * @throws ApiError
     */
    public createBrand(
        requestBody: CreateBrandDTO,
    ): CancelablePromise<CreateResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/brands',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns BrandDTO
     * @throws ApiError
     */
    public getBrand(
        id: string,
    ): CancelablePromise<BrandDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/brands/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public deleteBrand(
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/brands/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public updateBrand(
        id: string,
        requestBody: UpdateBrandDTO,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/brands/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
