import { useEffect, useState } from 'react';
import { useApiClient } from '../../data';
import {
  Button,
  VStack,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  HStack,
} from '@chakra-ui/react';
import { useLocation } from 'wouter';

export const WatchesList = () => {
  const [pageSize] = useState(30);
  const [page] = useState(1);
  const [, navigate] = useLocation();
  const { data: watches, refetch } = useApiClient('watches.getWatchList', {
    lazy: true,
  });

  useEffect(() => {
    refetch(pageSize, page);
  }, [refetch, pageSize, page]);

  return (
    <VStack spacing="12" alignItems="stretch">
      <HStack justifyContent="space-between">
        <Heading>Watches</Heading>
        <Button onClick={() => navigate('/create')}>Create</Button>
      </HStack>

      <TableContainer>
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Last Updated</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {watches?.map((watch) => (
              <Tr key={watch.id}>
                <Td>{watch.nickName || watch.modelNo}</Td>
                <Td>{watch.updatedAt}</Td>
                <Td>
                  <Button onClick={() => navigate(`/${watch.id}`)}>
                    Edit
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  );
};
