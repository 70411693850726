import axios from 'axios';
import { uploadDataParams } from './types';

export const uploadData = async ({
  file,
  signedUrl,
  part,
  chunkSize,
  onProgress,
}: uploadDataParams) => {
  const sentSize = part * chunkSize;
  const chunk = file.slice(sentSize, sentSize + chunkSize);

  let uploadedBytes = 0;

  const response = await axios.put(signedUrl, chunk, {
    headers: {
      'Content-Type': file.type,
      'x-amz-acl': 'private',
    },
    onUploadProgress: (event) => {
      uploadedBytes += event.bytes;

      onProgress({
        file,
        status: 'uploading',

        uploadedBytes,
      });
    },
  });

  return {
    etag: (response.headers['etag'] as string) || '',
  };
};
