import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, PropsWithChildren } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { withSlots } from '../../tools/withSlots';
import { Skeleton } from '../skeletons';

const BrandFormDataSchema = z.object({
  brand: z.string().min(1),
  model: z.string().min(1),
});

export type BrandFormData = z.infer<typeof BrandFormDataSchema>;

export type BrandsFormProps = PropsWithChildren<{
  onSubmit: (data: BrandFormData) => void;
  isLoading?: boolean;
  isSaving?: boolean;
  data?: BrandFormData;
  onClose?: () => void;
}>;

export type BrandsFormSlot = 'header';

export const { Component: BrandsForm, useRegisterSlot: useRegisterBrandFormSlot, useSlotsContext: useBrandFormSlots } = withSlots<
  BrandsFormSlot,
  BrandsFormProps
>(({ onSubmit, isSaving, isLoading, data, children, onClose }) => {
  const { getSlots } = useBrandFormSlots();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<BrandFormData>({
    defaultValues: data,
    resolver: zodResolver(BrandFormDataSchema),
  });

  useEffect(() => {
    if (data) reset(data);
  }, [reset, data]);

  const headerSlot = getSlots('header');

  return (
    <>
      {children}
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={8} width="400px" alignItems={'stretch'}>
          <HStack justifyContent="space-between">
            <Box>{headerSlot}</Box>
            {onClose && <Button onClick={onClose}>Close</Button>}
          </HStack>
          <FormControl isInvalid={!!errors.brand}>
            <FormLabel>Brand</FormLabel>
            <Skeleton isLoaded={!isLoading}>
              <Input {...register('brand')} />
            </Skeleton>

            {errors.brand ? (
              <FormErrorMessage>{errors.brand.message}</FormErrorMessage>
            ) : (
              <FormHelperText>
                Name of watch brand e.g <i>"Rolex"</i>
              </FormHelperText>
            )}
          </FormControl>

          <FormControl isInvalid={!!errors.model}>
            <FormLabel>Model</FormLabel>

            <Skeleton isLoaded={!isLoading}>
              <Input {...register('model')} />
            </Skeleton>
            {errors.model ? (
              <FormErrorMessage>{errors.model.message}</FormErrorMessage>
            ) : (
              <FormHelperText>
                Name of watch model e.g <i>"Daytona"</i>
              </FormHelperText>
            )}
          </FormControl>

          <HStack justifyContent={'right'} alignSelf={'stretch'}>
            <Button
              isDisabled={isLoading || isSaving}
              type="submit"
              colorScheme="green"
            >
              Save
            </Button>
          </HStack>
        </VStack>
      </form>
    </>
  );
});

export const BrandFormHeader = ({ children }: PropsWithChildren) => {
  useRegisterBrandFormSlot('header', children);
  return null;
};
